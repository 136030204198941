<template>
<div class="col-4">
<table class="table table-bordered">
      <thead>
        <tr>
          <th>Група</th>
          <th>Користувач</th>
          <th>Група умов</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item) in users" :key="item.id">
            <td class="text-center">
              <p v-for="role in item.roles" :key="role.id">
              <span v-if="role.name === 'manager'">Менеджер</span>
              <span v-if="role.name === 'dropshipper'">Дропшипер</span>
              </p>
            </td>
            <td class="text-center">{{ item.lastName }} {{ item.firstName }}</td>
            <td>
              <select class="form-control form-control-sm" @change="setGroupToUser(item.id, $event)" v-model="item.groupId">
                <option v-for="group in groups" :value="group.id" :key="group.id">{{ group.name }}</option>
              </select>
            </td>
          </tr>
      </tbody>
</table>
<!-- Pagination -->
<b-pagination size="sm" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"/>
</div>
</template>
<script>
import axios from 'axios'
export default {
  props: {
    groups: {
      required: true
    }
  },
  data: () => ({
    users: null,
    currentPage: 1,
    perPage: 15,
    totalItems: 0,
    urlParams: {}
  }),
  mounted () {
    this.getUsers()
  },
  methods: {
    async getUsers () {
      const params = this.buildQuery() !== '' ? '&' + this.buildQuery() : ''
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.get(apiUrl + 'checkbox/users?page=' + this.currentPage + params).then(resp => {
        if (resp.data) {
          const data = resp.data.data
          const meta = resp.data.meta
          this.users = data
          this.currentPage = meta.pagination.current_page
          this.perPage = meta.pagination.per_page
          this.totalItems = meta.pagination.total
        }
      })
    },
    async setGroupToUser (userId, e) {
      const groupId = Number.parseInt(e.target.value)
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'checkbox/user', { groupId: groupId, userId: userId }).then(resp => {
        if (resp.data && resp.data.status) {
          this.getUsers()
        }
      })
    },
    buildQuery () {
      return Object.entries(this.urlParams).map(pair => pair.map(encodeURIComponent).join('=')).join('&')
    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.getUsers()
      }
    }
  }
}
</script>
